<template>
  <div class="body mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <section class="header notice">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="goBack">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap font-bold font-white">切換數位圖書證</span>
      <div class="flexH width right"></div>
    </section>

    <!-- Main -->
    <section class="main notice">
      <div class="records flexV width padding">
        <div v-for="(item, index) in datas" :key="'item' + index">
          <a
            class="record flexH width margin relative shadow-md"
            @click.stop="detail(item)"
          >
            <div class="flexV width gap-1">
              <span class="fL font-normal cNeutral900 font-lg fz-100"
                >{{ item.title }}
              </span>
              <span class="font-normal cNeutra500">{{ item.company }}</span>
              <span
                class="fS font-normal cNeutra500 font-sm flex items-center"
                :style="item.status === 0 ? 'color: var(--k1)' : '正在使用中'"
              >
                {{ item.status === 0 ? "使用此圖書證" : "正在使用中" }}
              </span>
            </div>

            <div class="">
              <img v-if="item.status === 0" src="@/assets/icon/delete.svg" alt="" />
              <img v-if="item.status === 1" src="@/assets/icon/delete-gray.svg" alt="" />
            </div>
          </a>
          <div class="lineH"></div>
        </div>
      </div>

      <div class="records flexV width padding mb-3 px-2 text-center fM">
        至多可以有 8 張圖書證
      </div>

      <div class="buttons records flexV width padding">
        <div>
          <button @click="add()">新增圖書證</button>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      latestReadAt: 0,
      status: 0,
      userData: {
        city: "中華民國國民",
        id: "F157765078",
        name: "蔡函瑄",
        cardId: "10640003645993",
      },
      datas: [
        {
          id: 4,
          title: "蔡函瑄",
          company: "10640003645993",
          status: 1,
        },
        {
          id: 5,
          title: "楊繼逸",
          company: "10640003645952",
          status: 0,
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      if (that.user && that.user.userId) {
        //
      } else {
        // window.location.reload();
        next("/home");
      }
    });
  },
  mounted() {
    console.log("mounted!");
    // this.onSnapShot();

    //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
    // this.getLatestReadTime()
    // .then(() => {
    //     this.onSnapShot();
    // });

    setTimeout(() => {
      console.log("Notifications", this.notifications);
    }, 4000);
  },
  computed: {
    ...mapState(["user"]),
    // ...mapGetters({
    //     notifications: 'noti/notifications'
    // }),
    notifications() {
      let ns = this.$store.getters["noti/notifications"];
      // fake data
      // ns = [{id:1,title:"title1",text:"text1",ts:1720961906,unread:true},{id:2,title:"title2",text:"text2",ts:1720961906,unread:true}];
      return Object.values(ns).sort((a, b) => {
        return b.ts - a.ts;
      });
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    add() {
      this.$router.push("/digital-card/add");
    },
  },
};
</script>

<style scoped>
.tab {
  border-radius: 0 !important;
}

.record {
  padding: 16px !important;
}

button {
  color: white;
  border-radius: 20px !important;
  height: 51px !important;
}

img {
  width: 24 !important;
  height: 24px !important;
}
</style>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>
<style scoped src="@/assets/css/payment.css"></style>
