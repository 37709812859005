<template>
  <div class="body mobile">
    <!-- class: fixed, mobile -->
    <!-- Loading Spinner -->
    <!-- <div class="spinner"><div><div></div></div></div> -->
    <!-- Header -->
    <section class="header notice">
      <div class="flexH width">
        <a class="icon iconButton third" @click.prevent="goBack">
          <font-awesome-icon
            icon="fa-solid fa-chevron-left"
            size="lg"
          ></font-awesome-icon>
        </a>
      </div>
      <span class="fL nowrap font-bold font-white">新增數位圖書證</span>
      <div class="flexH width right"></div>
    </section>

    <!-- Main -->
    <section class="main notice">
      <!-- 紀錄 -->
      <div class="records flexV width padding">
        <div class="record mt-2">
          <div class="input rounded">
            <div class="icon">
              <font-awesome-icon icon="fa-solid fa-id-card"></font-awesome-icon>
            </div>
            <span class="fM nowrap gray label">國籍身分</span>
            <input
              type="text"
              class="fM"
              placeholder=""
              v-model="userData.city"
            />
          </div>
        </div>

        <div class="record mt-2">
          <div class="input rounded">
            <div class="icon">
              <font-awesome-icon icon="fa-solid fa-id-card"></font-awesome-icon>
            </div>
            <span class="fM nowrap gray label">
              身分證字號
              <span class="text-red-500">*</span>
            </span>
            <input
              type="text"
              class="fM"
              placeholder=""
              v-model="userData.id"
            />
          </div>
        </div>

        <div class="record mt-2">
          <div class="input rounded">
            <div class="icon">
              <font-awesome-icon icon="fa-solid fa-user"></font-awesome-icon>
            </div>
            <span class="fM nowrap gray label">
              姓名
              <span class="text-red-500">*</span>
            </span>
            <input
              type="text"
              class="fM"
              placeholder=""
              v-model="userData.name"
            />
          </div>
        </div>

        <div class="record mt-2">
          <div class="input rounded">
            <div class="icon">
              <font-awesome-icon
                icon="fa-solid fa-envelope"
              ></font-awesome-icon>
            </div>
            <span class="fM nowrap gray label">
              借書證號
              <span class="text-red-500">*</span>
            </span>
            <input
              type="text"
              class="fM"
              placeholder=""
              v-model="userData.cardId"
            />
          </div>
        </div>
      </div>

      <div class="records flexV width padding mb-3 px-2">
        若無實證圖書證，請先至圖書館辦理實體卡，才可申請數位圖書證綁定。
      </div>

      <div class="buttons records flexV width padding">
        <div v-if="status === 0">
          <button @click="add()">確認送出</button>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      latestReadAt: 0,
      status: 0,
      userData: {
        city: "中華民國國民",
        id: "F157765078",
        name: "蔡函瑄",
        cardId: "10640003645993",
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    // called before the route that renders this component is confirmed.
    // does NOT have access to `this` component instance,
    // because it has not been created yet when this guard is called!
    console.log(to, from);
    next((vm) => {
      let that = vm;
      if (that.user && that.user.userId) {
        //
      } else {
        // window.location.reload();
        next("/home");
      }
    });
  },
  mounted() {
    console.log("mounted!");
    // this.onSnapShot();

    //NOTE: Howard's fix: 現在notification直接從store取用，不用再原地去Firestore撈了
    // this.getLatestReadTime()
    // .then(() => {
    //     this.onSnapShot();
    // });

    setTimeout(() => {
      console.log("Notifications", this.notifications);
    }, 4000);
  },
  computed: {
    ...mapState(["user"]),
    // ...mapGetters({
    //     notifications: 'noti/notifications'
    // }),
    notifications() {
      let ns = this.$store.getters["noti/notifications"];
      // fake data
      // ns = [{id:1,title:"title1",text:"text1",ts:1720961906,unread:true},{id:2,title:"title2",text:"text2",ts:1720961906,unread:true}];
      return Object.values(ns).sort((a, b) => {
        return b.ts - a.ts;
      });
    },
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    add() {
      this.$router.push("/digital-card/add");
    },
  },
};
</script>

<style scoped>
.tab {
  border-radius: 0 !important;
}

.record {
  padding: 0 !important;
  margin-bottom: 0 !important;
  border-radius: 99px !important;
}

button {
  color: white;
  border-radius: 20px !important;
  height: 51px !important;
}

.label {
  width: 170px;
}
</style>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>
<style scoped src="@/assets/css/payment.css"></style>
